var isPage = "";
let scrollTimeId;
let Animate = {
	top: 0,
	show: 0
}
let animations = [];
let isSpecialPage = false;
var common = {
	topCount: 0,
	topD: 0,
	beforeTopD: 0,
	init: ()=>{
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");
		common.checkCookie();
		favorite.init();
		
		if(isPage != "index"){
			$("body").addClass("active");
		}
		if(isPage === "index"){
			home.init();
		}else if(isPage === "article" || isPage === "shop" || isPage === "eventcalendar" && !$("body").hasClass("disable")){
			article.init();
			if(isPage === "shop"){
				if( $("article").length > 0 ){

				}else{
					shopList.init();
				}
				setTimeout(function(){
					$body.removeClass("disable");
				}, 600);
			}else{
				setTimeout(function(){
					$body.removeClass("disable");
				}, 600);
			}
		}else if(isPage === "area_detail"){
			if( $("main").hasClass("page_special") ){
				special.init();
			}else{
				area.init();
			}
			setTimeout(function(){
				$body.removeClass("disable");
			}, 600);
		}else{
			if(isPage === "faq"){
				faq.init();
			}else if(isPage === "sitemap"){
				sitemap.init();
			}
			setTimeout(function(){
				$body.removeClass("disable");
				common.scrl();
			}, 600);
		}
		if( isPage === "eventcalendar" && !$("body").hasClass("detail") ){
			calendar.init();
		}
		if( isPage === "shop"){
			if($(".table_info").length > 0){
				$(".table_info tr").each(function(i){
					let $this = $(this);
					let val = $(this).find("td p").text();
					let val2 = $(this).find("td ul").length;
					let val3 = $(this).find("td div").eq(0).text();
					
					if(val === ""){
						$this.addClass("disable");
					}
					if(val2 > 0){
						$this.removeClass("disable");
					}
					if(val3.length > 0){
						$this.removeClass("disable");
					}
					//console.log(val);
				});
			}
		}
		//本文中にマップがある場合にスポットを表示
		if( $("#spotmap_article").length > 0){
			$(".latlng").each(function(i){
				let ll = [$(this).attr("data-lat"), $(this).attr("data-lng"), $(this).attr("data-pin-image")];
				articleSpots.push( ll );
				//console.log(ll);
			});
			article.makeMap();
		}
		if( $("main").hasClass("contact") ){
			contact.init();
		}
		if( $(".slideshow").length > 0 ){
			slideshow.init();
		}
		if(isPage === "news"){
			let contentH = $("#wrapper").outerHeight();
			if(contentH < window.innerHeight){
				$html.classList.add("nopagetop");
			}
		}
		common.resize();
		common.nav();
		
		$(document).off().on("scroll touchmove", function(){
			common.scrl();
			if( $html.classList.contains("show_reserve_nav") ){
				$html.classList.remove("show_reserve_nav");
			}
			clearTimeout(scrollTimeId);
			scrollTimeId = setTimeout( function () {
				common.scrollDirection();
				if( !$html.classList.contains("show_reserve_nav") ){
					$html.classList.add("show_reserve_nav");
				}
			}, 300 ) ;
		});
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};

		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
	},
	nav: ()=>{
		$("#glbnav a").not(`[target="_blank"]`).off().on("click", function(e){
			e.preventDefault();
			toGA($(this));
		});
		$("header .list_nav li a").off().on("click", function(e){
			e.preventDefault();
			toGA($(this));
		});
		function toGA($this){
			let cat = $this.attr("data-category");
			let thisLabel = $this.attr("data-ga");
			let link = $this.attr("href");
			common.sendGa(cat, thisLabel);
			location.href = $this.attr("href");
		}
		$("#nav_access ul li a").off().on("click", function(){
			let idx = $("#nav_access li a").index(this);
			//console.log(idx);
			let posY = $(".block_access").eq(idx).offset().top - $("header").height();
			common.scrollToPosition(posY, 1000);
		});
		$("a#btn_menu").off().on("click", function(){
			if( !$html.classList.contains("menu") ){
				$html.classList.add("menu");
				setTimeout(function(){
					$html.classList.add("show_menu");
				}, 600);
			}else{
				common.closeNav();
			}
		});
		$("#bg_glbnav").off().on("click", function(){
			common.closeNav();
		});
		//shop
		$(".btn_open_search").off().on("click", function(){
			let $this = $(this);
			let idx = $(".btn_open_search").index(this);
			if( !$this.hasClass("active") ){
				$this.addClass("active");
			}else{
				$this.removeClass("active");
			}
			$(".block_search").eq(idx).animate({
				height: "toggle", opacity: "toggle"
			});
		});
		$("a.pagetop").off().on("click", function(){
			if( $(".scroll-content").length > 0 ){
				scrollbar.scrollTo(0, 0, 600);
			}else{
				common.scrollToPosition(0,1000);
			}
		});
		
		$("a.btn_favorite").off().on("click", function(){
			let value = $(this).attr("data-id");
			let $this = $('a.btn_favorite[data-id="' + value + '"]');
			if( $this.hasClass("active") ){
				$this.removeClass("active");
				favorite.removeFavorite(value);
			}else{
				favorite.postFavorite(value);
				$this.addClass("active");
				favorite.checkFavorite(value);
			}
		});
		
		$("a.btn_star").off().on("click", function(){
			let value = $(this).attr("data-id");
			let $this = $(`a.btn_star[data-id="${value}"]`);
			//console.log(value, $this);
			if( $this.hasClass("active") ){
				$this.removeClass("active");
				star.removeStar(value);
			}else{
				$this.addClass("active");
				star.checkStar(value);
			}
		});
		//share
		let $this;
		$(".share li a").off().on("click", function(){
			$this = $(this);
			var name = $this.attr("data-sns");
			shareTxt(name);
		});
		function shareTxt(name){
			let url = location.href;
			if(name === "facebook"){
				//Facebook
				window.open(
					'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
					'facebooksharedialog',
					'width=626,height=436');
			}else if(name === "twitter"){
				//Twitter
				let title = $(".ttl_article h2 span").text();
				var txt = $this.attr("data-text");
				window.open(
					'http://twitter.com/share?url=' + encodeURIComponent(url) + "&text=" + title + " %0a",
					'twittersharedialog',
					'width=626,height=436');
			}else{
				//LINE
				let title = $(".ttl_article h2 span").text();
				var txt = $this.attr("data-text") + "\n" + url;
				window.open(
					'http://line.me/R/msg/text/?' + encodeURIComponent(txt),
					'linesharedialog',
					'width=626,height=436');
			}
		}
		//addressのコピー
		$("a.copy_address").off().on("click", function(){
			var copyTarget = $("input.address")[0];
			copyTarget.select();
			var range = document.createRange();
			range.selectNodeContents(copyTarget);
			var sel = window.getSelection();
			sel.removeAllRanges();
			sel.addRange(range);
			copyTarget.setSelectionRange(0, 999999);
			document.execCommand("copy");
			alert("住所をコピーしました。");
		});
	},
	closeNav: function(){
		if( $html.classList.contains("menu") ){
			$html.classList.add("close_menu");
			$html.classList.remove("show_menu");
			setTimeout(function(){
				$html.classList.remove("menu");
				setTimeout(function(){
					$html.classList.remove("close_menu");
				}, 600);
			}, 10);
		}
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");
		let sizes = [];
		
		if (window.matchMedia('all and (min-width : 864px)').matches) {
			isSmallScreen = false;
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			isSmallScreen = true;
		}
		if(isPage === "index"){
			if (window.matchMedia('all and (min-width : 1024px)').matches) {
				$("#mapcontainer").css({
					"width": (winW - 380) + "px"
				});
			} else if(window.matchMedia('all and (min-width : 0px)').matches) {
				$("#mapcontainer").css({
					"width": ""
				});
			}
			if(isSmallScreen){
				sizes = fs(16,9, 320, 568, winW, winH);
				$(".leaflet-right").css({
					"right": ""
				});
			}else{
				sizes = fs(16,9, 640, 360, winW, winH);
				let r = ( $("#mapcontainer").width() - $("#block_map").width() )/2 - 10;
				$(".leaflet-right").css({
					"right": r + "px"
				});
			}
			//console.log(sizes);
			$("#ytPlayer").css({
				"width": sizes[0] + "px",
				"height": sizes[1] + "px",
				"top": sizes[3] + "px",
				"left": sizes[2] + "px"
			})
			if(osm.map){
				osm.map.invalidateSize();
			}
		}
		animations = [];
		$(".a").each(function(i){
			let _a 	= object(Animate);
			_a.top 	= Math.floor( $(this).offset().top );
			_a.show = Math.floor( _a.top + window.innerHeight/4 );
			animations.push(_a);
		});
		if(isSpecialPage){
			special.resize();
		}
		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	scrollToPosition: (posY, duration) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		$page.animate({
			scrollTop : posY + "px"
		},{
			"duration" 	: duration,
			"easing" 	: ease
		});
	},
	scrl: () =>{
		var topD = $(document).scrollTop(),
			btmD = topD + $(window).height();
		
		common.scrollCommon(topD, btmD);
		//pagetop
		/*
		let fixPos = Math.floor( $("footer").offset().top );
		if(btmD >= fixPos){
			if( !$html.classList.contains("fix_pagetop") ){
				$html.classList.add("fix_pagetop");
			}
		}else{
			if( $html.classList.contains("fix_pagetop") ){
				$html.classList.remove("fix_pagetop");
			}
		}
		if(topD >= window.innerHeight){
			if( !$html.classList.contains("show_pagetop") ){
				$html.classList.add("show_pagetop");
			}
			if( $("#float_news").length > 0 ){
				if( $("#float_news").hasClass("active") ){
					loadpost.closeNews();
				}
			}
		}else{
			if( $html.classList.contains("show_pagetop") ){
				$html.classList.remove("show_pagetop");
			}
		}
		//header
		let smallPos = window.innerHeight/3;
		if(topD >= smallPos){
			if( !$html.classList.contains("small_header") ){
				$html.classList.add("small_header");
			}
		}else{
			if( $html.classList.contains("small_header") ){
				$html.classList.remove("small_header");
			}
		}
		let diff = topD - common.beforeTopD;
		if(diff < -300){
			if( !$html.classList.contains("up") ){
				$html.classList.add("up");
				$html.classList.remove("down");
			}
		}else{
			if( !$html.classList.contains("down") ){
				$html.classList.remove("up");
				$html.classList.add("down");
			}
		}
		*/
		
		var alen = animations.length;
		//console.log(animations);
		for(var i = 0; i < alen; i++){
			if(i === alen - 1){
				if(btmD >= animations[i].show){
					activeBlock(i)
				}
			}else{
				if(btmD >= animations[i].show){
					activeBlock(i)
				}
			}
		}
		function activeBlock(i){
			var $block = $(".a").eq(i);
			if(!$block.hasClass("animate")){
				$block.addClass("animate");
				setTimeout(function(){
					$block.addClass("animated");
				}, 600);
			}
		}
		if(isPage === "shop" && !$("body").hasClass("detail") ){
			let docH = $("footer").offset().top;
			if(btmD >= docH - 100){
				if(shopList.canLoad){
					shopList.canLoad = false;
					shopList.loadMore();
				}
			}
		}
		if(isPage === "index"){
			let $mapBtn = $("#btn_toggle_map_sp");
			if( btmD >= $("footer").offset().top - 60 ){
				if( !$mapBtn.hasClass("hide") ){
					$mapBtn.addClass("hide");
				}
			}else{
				if( $mapBtn.hasClass("hide") ){
					$mapBtn.removeClass("hide");
				}
			}
		}
		if(isSpecialPage){
			if(isSP || isIPad || isIE11){
				special.scroll(topD, btmD);
			}
		}
	},
	scrollCommon: function(topD, btmD){
		common.topD = topD;
		//pagetop
		let fixPos = Math.floor( $("footer").offset().top );
		if( $("body").attr("id") != "area_detail" ){
			if(btmD >= fixPos){
				if( !$html.classList.contains("fix_pagetop") ){
					$html.classList.add("fix_pagetop");
				}
			}else{
				if( $html.classList.contains("fix_pagetop") ){
					$html.classList.remove("fix_pagetop");
				}
			}
		}
		
		if(topD >= window.innerHeight){
			if( !$html.classList.contains("show_pagetop") ){
				$html.classList.add("show_pagetop");
			}
			if( $("#float_news").length > 0 ){
				if( $("#float_news").hasClass("active") ){
					loadpost.closeNews();
				}
			}
		}else{
			if( $html.classList.contains("show_pagetop") ){
				$html.classList.remove("show_pagetop");
			}
		}
		//header
		let smallPos = window.innerHeight/3;
		if(topD >= smallPos){
			if( !$html.classList.contains("small_header") ){
				$html.classList.add("small_header");
			}
		}else{
			if( $html.classList.contains("small_header") ){
				$html.classList.remove("small_header");
			}
		}
		let diff = topD - common.beforeTopD;
		//console.log(diff, topD, common.beforeTopD);
		if(diff < -300){
			if( !$html.classList.contains("up") ){
				$html.classList.add("up");
				$html.classList.remove("down");
			}
		}else{
			if( !$html.classList.contains("down") ){
				$html.classList.remove("up");
				$html.classList.add("down");
			}
		}
	},
	scrollDirection: function(){
		common.topCount = 0;
		common.beforeTopD = $(document).scrollTop();
	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
	},
	checkCookie: function(){
		//クッキー取得に同意
		let agree = Cookies.get("vison_agree_cookie");
		//console.log(agree);
		if(!agree){
			$("a#btn_agree_cookie").off().on("click", function(){
				Cookies.set('vison_agree_cookie', 1, {
					//secure: true,
					expires: favorite.expire,
					domain: location.domainname
				});
				$("#float_agree_cookie").addClass("delete");
			})
		}else{
			$("#float_agree_cookie").remove();
		}
	},
	sendGa: function(cat, label){
		console.log('ga-event:', 'click', cat, label);
		gtag('event', 'click', {
			'event_category': cat,
			'event_label': label
		});
	}
}
document.addEventListener('DOMContentLoaded', function () {
	isPage = $("body").attr("id");
	common.init();
});