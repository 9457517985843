
let osm = {
	map: "",
	bound: "",
	markerList: [],
	optionList: [],
	articles: [],
	articleMarkers: "",
	mapCreated: false,
	init: function(){
		osm.markerList = [];
		let basePos = [34.460026, 136.513157];
		//let basePos = [35.622357, 139.684756];
		if(isSP){
			$("#btn_current_position").off().on("click", function(){
				osm.addMySpot();
			});
		}
		osm.map = L.map('mapcontainer',
			{
				center: basePos,
				minZoom: 6,//12
				maxZoom: 18,
				zoom: 17,
				zoomControl: false,
				doubleClickZoom: false,
			}
		);
		osm.map.scrollWheelZoom.disable();
		L.control.zoom(
			{ position: 'bottomright' }
		).addTo(osm.map);
		//地理院地図の標準地図タイル
		var gsi = L.tileLayer('https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png', { 
			attribution: "<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院タイル</a>"
		});
		
		//地理院地図の淡色地図タイル
		var gsipale = L.tileLayer('http://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png', {
			attribution: "<a href='http://portal.cyberjapan.jp/help/termsofuse.html' target='_blank'>地理院タイル</a>"
		});
		
		//オープンストリートマップのタイル
		var osmTile = L.tileLayer('http://tile.openstreetmap.jp/{z}/{x}/{y}.png',{
			attribution: "<a href='http://osm.org/copyright' target='_blank'>OpenStreetMap</a> contributors"
		});
		
		L.TileLayer.Kitten = L.TileLayer.extend({
			getTileUrl: function(coords) {
				////return `http://tile.openstreetmap.jp/${coords.z}/${coords.x}/${coords.y}.png`;
				let defaultMapURL = `//tile.openstreetmap.jp/${coords.z}/${coords.x}/${coords.y}.png`;
				if(coords.z === 12){
					if(coords.x === 3601 && coords.y === 1629){
						return `/assets/tile/${coords.z}/${coords.x}_${coords.y}.png`;
					}else{
						return defaultMapURL;
					}
				}else if(coords.z === 13){
					if(coords.x === 7202 && coords.y === 3259){
						return `/assets/tile/${coords.z}/${coords.x}_${coords.y}.png`;
					}else{
						return defaultMapURL;
					}
				}else if(coords.z === 14){
					if(coords.x >= 14404 && coords.x <= 14405 && coords.y === 6519){
						return `/assets/tile/${coords.z}/${coords.x}_${coords.y}.png`;
					}else{
						return defaultMapURL;
					}
				}else if(coords.z === 15){
					if(coords.x >= 28809 && coords.x <= 28810 && coords.y >= 13038 && coords.y <= 13039){
						return `/assets/tile/${coords.z}/${coords.x}_${coords.y}.png`;
					}else{
						return defaultMapURL;
					}
				}else if(coords.z === 16){
					if(coords.x >= 57618 && coords.x <= 57621 && coords.y >= 26077 && coords.y <= 26079){
						return `/assets/tile/${coords.z}/${coords.x}_${coords.y}.png`;
					}else{
						return defaultMapURL;
					}
				}else if(coords.z === 17){
					if(coords.x >= 115237 && coords.x <= 115243 && coords.y >= 52155 && coords.y <= 52159){
						return `/assets/tile/${coords.z}/${coords.x}_${coords.y}.png`;
					}else{
						return defaultMapURL;
					}
				}else if(coords.z === 18){
					if(coords.x >= 230474 && coords.x <= 230487 && coords.y >= 104310 && coords.y <= 104320){
						return `/assets/tile/${coords.z}/${coords.x}_${coords.y}.png`;
					}else{
						return defaultMapURL;
					}
				}else{
					return defaultMapURL;
				}
			}
		});
		
		L.tileLayer.kitten = function() {
			return new L.TileLayer.Kitten();
		}
		
		L.tileLayer.kitten().addTo(osm.map);
		//baseMapsオブジェクトのプロパティに3つのタイルを設定
		var baseMaps = {
			"地理院地図" 	: gsi,
			"淡色地図" 	: gsipale,
			"オープンストリートマップ"  : osmTile
		};
		//layersコントロールにbaseMapsオブジェクトを設定して地図に追加
		//コントロール内にプロパティ名が表示される
		//L.control.layers(baseMaps, { collapsed: false }).addTo(osm.map);
		//どのマップ画像を使うか選択
		//gsipale.addTo(osm.map);
		//gsirelief.addTo(osm.map)
		
		osm.bound = L.latLngBounds(basePos);
		osm.nav();
		let isMove = false;
		//マップのイベント
		osm.map.on("zoomend", function(e){
			//isMove = false;
			//console.log("マップのズームレベルが変更されました。");
		});
		/*
		osm.map.on("resize", function(e){
			
		});
		osm.map.on("load", function(e){
			console.log("マップが初期化されました。");
		});
		osm.map.on("movestart", function(e){
			isMove = true;
		});
		osm.map.on("zoomstart", function(e){
			isMove = false;
		});
		*/
		osm.map.on("moveend", function(e){
			//記事が変更できる状態か確認する
			return false;
			/*
			if( home.canChangeArticle && isMove){
				home.canChangeArticle = false;
				home.hidePost();
				let center = $(".leaflet-control-mapcentercoord").text();
				let arry = center.split("|");
				home.latlng = [ Number( arry[0].replace("º", "").replace(" ", "") ),Number( arry[1].replace("º", "").replace(" ", "") )];
				console.log("地図の中心:", home.latlng)
				//移動後にポストをアップデートする
				setTimeout(function(){
					osm.checkUpdate();
				}, 400);
			}
			*/
		});
	},
	nav: function(){
		let $btnToggleCategories = $("#btn_toggle_categories");
		let thisLabel = "";
		$(".btn_open_map").off("click").on("click", function(){
			if( !$html.classList.contains("show_map") ){
				$html.classList.add("show_map");
				loadpost.closeSearchBlock();
				$("header").addClass("narrow");
				thisLabel = "map open";
				setTimeout(function(){
					$("#list_magazine li.p").each(function(i){
						let $this = $(this);
						if( !$this.hasClass("animate") ){
							$this.addClass("animate");
						}
					});
					osm.map.invalidateSize();
				}, 600);
				osm.nav();
			}else{
				//モーダルが出ていたら閉じる
				osm.closeModalSearch();
				$html.classList.remove("show_map");
				thisLabel = "map close";
				if( $btnToggleCategories.hasClass("active") ){
					$btnToggleCategories.removeClass("active");
				}
				$("#block_magazine .nav_categories").css("display", "");
				if(isSmallScreen){
					$(".leaflet-right").css({
						"right": ""
					});
				}else{
					let r = ( $("#mapcontainer").width() - 334 )/2 - 10;
					$(".leaflet-right").css({
						"right": r + "px"
					});
				}
				setTimeout(function(){
					osm.map.invalidateSize();
					$("header").removeClass("narrow");
					if( $(".nav_list").is(":hidden") ){
						$(".nav_list").css("display", "");
					}
				}, 600);
			}
			let cat = "マップを開くボタン";
			common.sendGa(cat, thisLabel);
		});
		//マップオープン後
		$("#map_filter_header a#btn_open_filter").off().on("click", function(){
			$("#block_magazine .nav_list").animate({
				height: "toggle", opacity: "toggle"
			}, 400);
		});
		
		$btnToggleCategories.off().on("click", function(){
			let $this = $(this);
			if( !$this.hasClass("active") ){
				$this.addClass("active");
				$(".nav_list").addClass("active");
			}else{
				$this.removeClass("active");
				$(".nav_list").removeClass("active");
				osm.closeModalSearch();
			}
			$("#block_magazine .nav_categories").animate({
				height: "toggle", opacity: "toggle"
			}, 300);
		});
	},
	closeModalSearch: function(){
		if( $("#modal_search_list").hasClass("open") ){
			$("#modal_search_list .search_list_inner").animate({
				height: "toggle", opacity: "toggle"
			}, 400);
			$(".nav_search li a").removeClass("active");
			$("#modal_search_list").removeClass("open");
		}
	},
	addOptionSpots: function(data){
		//一度読み込んだら触ることはない
		let markers = [];
		let starArry = Cookies.get("vison_star");
		let starlist = [];
		if(!starArry){
			starlist = [];
		}else{
			starlist = JSON.parse(starArry);
		}
		for (var i = 0; i < osm.optionList.length; i++) {
			let data 		= osm.optionList[i];
			var mpoint 		= [data.latlng[0], data.latlng[1]];
			
			//console.log(data);
			let isStar = false;
			for(var j = 0; j < starlist.length; j++){
				let id = "shop_" + starlist[j];
				if(data.id === id){
					isStar = true;
				}
			}
			let thisMarker;
			if(isStar){
				let x = 24;
				let y = 33;
				let starURL = "/mapicons/icon_star.svg";
				thisMarker 	= L.icon({ iconUrl: starURL, iconRetinaUrl: starURL, iconSize: [x,y], iconAnchor: [x/2, y], popupAnchor: [0, -y], className: "spot_option icon-" + data.id });
			}else{
				let x = 16;
				let y = 22;
				if(data.related.length > 0){
					x = 24;
					y = 33;
				}
				
				thisMarker 	= L.icon({ iconUrl: data.icon, iconRetinaUrl: data.icon, iconSize: [x,y], iconAnchor: [x/2, y], popupAnchor: [0, -y], className: "spot_option icon-" + data.id });
			}
			let mapMarker 	= L.marker(mpoint, { icon: thisMarker });
			//ポップアップの作成
			let img = "";
			let subcategory = "";
			let related = "";
			if(data.thumbnail != ""){
				img = `<div class="img_popup"><figure style="background-image: url(${data.thumbnail});"></figure></div>`;
			}
			if(data.subcategory != ""){
				subcategory = `<p class="shop_category">${data.subcategory}</p>`;
			}
			if(data.related.length > 0){
				related += `<div class="block_relative_posts">
							<div class="ttl_posts">
								<h4>ショップ・施設の関連記事</h4>
							</div>
							<ul class="relative_posts">
							`;
				for(var r = 0; r < data.related.length; r++){
					let post = data.related[r];
					//console.log(post);
					related += `<li>
									<a href="${post.link}" title="${post.name}">
										<div class="col2">
											<div class="img_thumbnail"><figure style="background-image: url(${post.thumbnail})"></figure></div>
											<div class="ttl_post">
												<h6>${post.name}</h6>
											</div>
										</div>
									</a>
								</li>`;
				}
				related += `</ul></div>`
			}
			let comment = "";
			let shopID = data.id.replace("shop_", "");
			//-console.log(data, data.name, shopID);
			if(data.name === ""){
				//ランドマークなのでリンクなし
				comment = `<div class="popup_content">
								<div class="popup_content_inner">
									${img}
									<div class="ttl">
										<h5>${data.name}</h5>
										${subcategory}
									</div>
									${related}
								</div>
							</div>`;
			}else{
				comment = `<div class="popup_content">
								<div class="popup_content_inner">
									<a href="https://vison.jp/shop/detail.php?id=${shopID}">
										${img}
										<div class="ttl">
											<h5>${data.name}</h5>
											${subcategory}
										</div>
									</a>
									${related}
								</div>
							</div>`;
			}
			mapMarker.bindPopup(comment);
			markers.push(mapMarker);
			//トップの初回ロード時
			if(!loadpost.isReady){
				if(i === osm.optionList.length - 1){
					indexLoadList[4] = 1;
				}
			}
		}
		let optionMarkerLayer = L.layerGroup(markers);
		optionMarkerLayer.addTo(osm.map);
	},
	addSpots: function(data){
		//console.log(data.latlng);
		//console.log("osm.article:", osm.articles)
		for(var i = 0; i < data.post.length; i++){
			let d = data.post[i];
			//console.log(d);
			if( !d.latlng[0] ){
				//console.log("article", d.title, "has no latlng");
			}else{
				var mpoint 		= [d.latlng[0], d.latlng[1]];
				let url 		= this.getMarker("post");
				let thisMarker 	= L.icon({ iconUrl: url, iconRetinaUrl: url, iconSize: [32, 43], iconAnchor: [16, 22], popupAnchor: [0, -22],className: "spot_article disable icon-" + d.id });
				console.log(d)
				var popupHTML 	= `
					<div class="popup_content">
						<figure><img src="${d.thumbnail}" alt="${d.title}" /></figure>
						<p>${d.title}</p>
						<a href="${d.link}" title="詳細を見る"><span>詳細を見る</span></a>
					</div>
				`;
				//ポップアップオブジェクトを作成
				var popup 		= L.popup({ maxWidth: 150 }).setContent(popupHTML);
				let mapMarker 	= L.marker(mpoint, { icon: thisMarker }).bindPopup( popup );
				osm.articles.push(mapMarker);
			}
		}
		//console.log("スポットのある記事", osm.articles);
		osm.articleMarkers = L.layerGroup(osm.articles);
		osm.articleMarkers.addTo(osm.map);
		if(!osm.mapCreated){
			osm.mapCreated = true;
		}
	},
	removeSpot: function(){
		osm.map.removeLayer();	
	},
	showNewSpot: function(){
		let len = $("img.spot_article.disable").length,
			count = 0;
		
		showSpot();
		function showSpot(){
			$(".spot_article.disable").eq(0).removeClass("disable");
			count++;
			setTimeout(function(){
				if( count >= len ){
					return false
				}else{
					showSpot();
				}
			}, 33);
		}
	},
	changeZoom: function(){
		//console.log( osm.bound );
		//ここがうまくいってない、もしくは見えないピンがいてピンのエリアが拡大してしまう
		//osm.map.fitBounds(osm.bound);
	},
	getMarker: function(category){
		let c = category,
			url = "";
		if(c === "information"){
			url = "/assets/images/common/map/icon_information.svg";
		}else if(c === "parking"){
			url = "/assets/images/common/map/icon_parking.svg";
		}else if(c === "souvenir"){
			url = "/assets/images/common/map/icon_souvenir.svg";
		}else if(c === "toilet"){
			url = "/assets/images/common/map/icon_toilet.svg";
		}else if(c === "post"){
			url = "/assets/images/common/map/icon_restaurant.svg";
		}else if(c === "restaurant"){
			url = "/assets/images/common/map/icon_restaurant.svg";
		}else{
			url = "/assets/images/common/map/icon_default.svg";
		}
		return url;
	},
	checkFloatModal: function(){
		let $modal = $("#modal_search_list");
		if( $html.classList.contains("show_map") ){
			if( $("#modal_search_list").hasClass("open") ){
				//カテゴリが開いている
				//マップが表示されている時
				let top = $(".nav_filter li a.active").offset().top - $("#wrapper").css("padding-top").replace("px", "") + 16;
				if(!top){
					$modal.css({
						"top": ""
					});
				}else{
					$modal.css({
						"top": top + "px"
					});
				}
			}else{
			}
		}else{
			//マップが表示されてない時
			$modal.css({
				"top": ""
			});
		}
	},
	checkUpdate: function(){
		if( $html.classList.contains("show_map") ){
			$html.classList.add("update");
			setTimeout(function(){
				loadpost.getPostByLatLng();
			}, 400);
		}
	},
	changeFilterNavText: function($this){
		let iconName = $this.find(".icon_text p").text(),
		textJp = $this.find(".nav_jp").text(),
		textEn = $this.find(".nav_en").text();
		$("#btn_open_filter .icon_text p").html(iconName);
		$("#btn_open_filter .txt_r .nav_jp").html(textJp);
		$("#btn_open_filter .txt_r .nav_en").html(textEn);
		
	},
	addMySpot: function(){
		//console.log("add my spot")
		if (navigator.geolocation) {
			/* Geolocation APIを利用できる環境向けの処理 */
			navigator.geolocation.getCurrentPosition(function(position){
				var mpoint 	= [position.coords.latitude, position.coords.longitude];
				console.log(mpoint);
				let url 		= "/assets/images/common/icon/icon_me.svg";
				let thisMarker 	= L.icon({ iconUrl: url, iconRetinaUrl: url, iconSize: [16, 16], iconAnchor: [16, 16], popupAnchor: [0, -8],className: "spot_article icon-me" });
				
				//ポップアップオブジェクトを作成
				let mapMarker 	= L.marker(mpoint, { icon: thisMarker });
				//let meLayer = L.layerGroup(mapMarker);
				mapMarker.addTo(osm.map);
				$("#btn_current_position").css("display","none");
				setTimeout(function(){
					$("img.icon-me").remove();
					$("#btn_current_position").css("display","block");
				}, 10000);
			}, errorCallback);
		} else {
			/* Geolocation APIを利用できない環境向けの処理 */
		}
		let errorCallback = function(error) {}
	}
}