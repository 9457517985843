let magazine;
let home = {
	searchBy 		: "",
	canChangePost 	: true,
	category 		: "",
	cityid 			: "",
	areaid 			: "",
	tag 			: "",
	favorites 		: "",
	latlng 			: [],
	page 			: 0,
	maxPage 		: 0,
	count 			: 0,
	canChangeArticle: true,
	init: function(){
		magazine = new Vue({
			el: "#block_magazine",
			data: {
				posts		: [],
				categories 	: [],
				tags 		: [],
				allposts 	: [],
				areas 		: [],
				areazones 	: [],
				maxPage		: 0,
				maxCount	: 0,
				page		: 0,
				each		: 9,
				isArea		: false,
				isFiltered 		: false,
				filteredName	: "",
				filterArea : {}
			},
			components: {
				vPost,
				vCategories,
				vTags,
				vAreas,
				vAreazones
			},
			mounted(){
				//初回ロード時、マウントされたら
				
			},
			updated(){
				//DOMがアップデートされたら
				common.resize();
			},
			methods: {
				loadMore: function(){
					magazine.page += 1;
					if(magazine.page === magazine.maxPage - 1){
					}
					loadpost.loadMore();
					//console.log("loadMore", home.category, "page:", magazine.page,"max:", magazine.maxPage);
				}
			}
		});
		checkYouTube();
		function checkYouTube(){
			if(ytReady){
				home.checkURL();
				osm.init();
				loadpost.init();
				ytMovie.id = $("#ytPlayer").attr("data-id");
				//ytMovie.id = "fAhJaK_RZ64";
				let visited = Cookies.get("vison_visited");
				
				ytMovie.delete();
				/*
				if(visited === "1"){
					ytMovie.delete();
				}else{
					ytMovie.init();
				}*/
				
				/*ytMovie.init();*/
			}else{
				setTimeout(function(){
					checkYouTube();
				},33);
			}
		}
	},
	nav: function(){
		/*
		$("a.btn_open_search").off().on("click", function(){
			$(".block_text_search").toggleClass("open");
		});
		$("a.btn_close_search").off().on("click", function(){
			$(".block_text_search").toggleClass("open");
		});
		*/
		//カテゴリで絞り込み
		//すべて、のカテゴリが静的出力なのでvuejs側のmethodではやらない
		$("ul#nav_category li a").off("click").on("click", function(){
			let $this = $(this);
			loadpost.closeSearchBlock();
			if( home.canChangePost ){
				home.canChangePost = false;
				let cat = $this.attr("data-category");
				if(cat === "all"){
					home.changeURL("/");
				}else{
					home.changeURL("/?category=" + cat);
				}
			}
			if( !$html.classList.contains("sort") ){
				$html.classList.add("sort");
			}else{
				$html.classList.remove("sort");
			}
		});
		$(".filter_favorite a").off().on("click", function(){
			let $this = $(this);
			loadpost.closeSearchBlock();
			if( !$this.hasClass("active") ){
				$this.addClass("active");
				home.changeURL("/?favorite=true");
			}else{
				$this.removeClass("active");
				home.changeURL("/");
			}
		});
	},
	changeURL: function(value){
		let url = value;
		window.history.pushState(null, null, url);
		this.checkURL();
	},
	checkURL: function(){
		let param 	= location.search.substring(1),
			pair 	= param.split('&');
		
		let pages 	= location.pathname;
		let arry 	= pages.split("/");
		let last 	= arry[arry.length-1];
		//console.log(param)
		home.hidePost();
		setTimeout(function(){
			home.resetFilter();
			if(param === ""){
				//console.log("すべて");
				magazine.posts = [];
				home.resetFilterNav();
				let $this = $(`#nav_category li a[data-category="all"]`);
				$this.addClass("active");
				osm.changeFilterNavText($this);
				loadpost.getPost();
				home.checkFiltered();
				home.searchBy = "";
			}else{
				let hasSearch 	= false;
				let searchBy  	= "";
				
				for(var i = 0; pair[i]; i++) {
					var kv = pair[i].split('=');
					if(kv[0] === "category"){
						home.category 	= kv[1];
						hasSearch 		= true;
						searchBy 		= "category";
					}else if( kv[0] === "favorite" ){
						hasSearch 		= true;
						home.favorites 	= favorite.getMyFavorite();
						searchBy 		= "favorite";
						magazine.isFiltered = true;
					}else if(kv[0] === "city"){
						home.cityid = kv[1];
						hasSearch 	= true;
						searchBy 	= "cityid";
						magazine.isArea = true;
						magazine.isFiltered = true;
					}else if(kv[0] === "area"){
						home.areaid = kv[1];
						hasSearch 	= true;
						searchBy 	= "area";
						magazine.isFiltered = true;
					}else if( kv[0] === "tag" ){
						home.tag 	= kv[1];
						hasSearch 	= true;
						searchBy 	= "tag";
						magazine.isFiltered = true;
					}
				}
				if(!hasSearch){
					//通常のロード
					loadpost.getPost();
				}else{
					magazine.page = 0;
					magazine.posts = [];
					//console.log(searchBy,"で絞り込み");
					loadpost.getFilteredPost();
					//removeclass
					home.resetFilterNav();
					//console.log(searchBy);
					
					if( searchBy === "category"){
						let $this = $(`#nav_category li a[data-category="${home.category}"]`);
						$this.addClass("active");
						osm.changeFilterNavText($this);
					}else if( searchBy === "tag" ){
						if( $(".search_list_inner").is(":visible") ){
							loadpost.toggleSearchBlock(0);
						}
						$(`#list_search_tag li a[data-filter-id="${home.tag}"]`).addClass("active");
						//絞り込み時に出すタイトル
						magazine.filteredName = "#" + $(`#list_search_tag li a[data-filter-id="${home.tag}"]`).attr("title");
						$("#block_filtered_header").addClass("tag");
						
					}else if( searchBy === "cityid" ){
						if( $(".search_list_inner").is(":visible") ){
							loadpost.toggleSearchBlock(1);
						}
						console.log("cityID:", home.cityid);
						$(`#list_search_city li a[data-filter-id="${home.cityid}"]`).addClass("active");
						//絞り込み時に出すタイトル
						//magazine.filteredName = $(`#list_search_place li a[data-filter-id="${home.cityid}"]`).attr("title");
						
					}else if( searchBy === "area" ){
						if( $(".search_list_inner").is(":visible") ){
							loadpost.toggleSearchBlock(1);
						}
						$(`#list_search_area li a[data-filter-id="${home.areaid}"]`).addClass("active");
						//絞り込み時に出すタイトル
						magazine.filteredName = $(`#list_search_area li a[data-filter-id="${home.areaid}"]`).attr("title");
						$("#block_filtered_header").addClass("visonarea");
					}else if(  searchBy === "favorite" ){
						$(`#nav_category li a[data-category="favorite"]`).addClass("active");
					}
					home.searchBy = searchBy;
					//console.log(magazine.filteredName)
				}
				home.checkFiltered();
			}
		}, 400);
		
	},
	hidePost: function(){
		$("#block_magazine").addClass("disable");
	},
	resetFilter: function(){
		home.category 	= "",
		home.tag 		= "";
		home.cityid 	= "";
		home.areaid 	= "";
		home.favorites 	= "";
		home.latlng 	= [];
		home.page 		= 0;
		osm.articles 	= [];
		$("#block_filtered_header").removeClass();
		//エリアでソートか否か
		magazine.isArea = false;
		magazine.isFiltered = false;
		
		magazine.filteredName = "";
		//マップのピンを削除
		if(!osm.mapCreated){
			
		}else{
			osm.map.removeLayer(osm.articleMarkers);
		}
	},
	resetFilterNav: function(){
		$("#nav_category li a").removeClass();
		$("#list_search_tag li a").removeClass();
		$("#list_search_city li a").removeClass();
		$("#list_search_area li a").removeClass();
	},
	filterMapPin: function(){
		
	},
	checkFiltered: function(){
		if(!magazine.isFiltered){
			$("#btn_open_filter").addClass("active");
		}else{
			$("#btn_open_filter").removeClass("active");
		}
	}
}