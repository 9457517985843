var vTags = {
	name : "vTags",
	props: ['tag'],
	template: `<li><a href="javascript:void(0);" :title=tag.name :data-filter-id=tag.id v-on:click="getTag(tag.id)"><span>{{tag.name}}</span></a></li>`,
	methods: {
		getTag: function(tagID){
			if(home.canChangeArticle){
				home.canChangeArticle = false;
				let url = "/?tag=" + tagID;
				home.changeURL(url);
			}
		}
	}
}

var vAreas = {
	name : "vAreas",
	props: ['area'],
	template: `<li><a href="javascript:void(0);" :title=area.name :data-filter-id=area.id v-on:click="getArea(area.id)"><span>{{area.name}}</span></a></li>`,
	methods: {
		getArea: function(areaID){
			//cityid
			if(home.canChangeArticle){
				home.canChangeArticle = false;
				let url = "/?city=" + areaID;
				home.changeURL(url);
			}
		}
	}
}

var vAreazones = {
	name : "vAreazones",
	props: ['areazone'],
	template: `<li><a href="javascript:void(0);" :title=areazone.name :data-filter-id=areazone.id v-on:click="getArea(areazone.id)"><span>{{areazone.name}}</span></a></li>`,
	methods: {
		getArea: function(areaID){
			//areaid
			if(home.canChangeArticle){
				home.canChangeArticle = false;
				let url = "/?area=" + areaID;
				home.changeURL(url);
			}
		}
	}
}
