var favorite = {
	expire: 14,
	list: [],
	init: function(){
		this.checkCookie();
		star.checkCookie();
	},
	addFavoriteToPost: function(){
		for(var i = 0; i < favorite.list.length; i++){
			let f = parseInt( favorite.list[i] );
			for(var j = 0; j < $("a.btn_favorite").length; j++){
				let $this = $("a.btn_favorite").eq(j);
				if( parseInt( $this.attr("data-id") ) === f ){
					if( !$this.hasClass("active") ){
						$this.addClass("active");
					}
				}
			}
		}
	},
	removeFavorite: function(value){
		for(var i = 0; i < favorite.list.length; i++){
			let f = parseInt( favorite.list[i] );
			if(parseInt(value) === f){
				favorite.list.splice(i, 1);
				let arry = JSON.stringify(favorite.list);
				favorite.set(arry);
			}
		}
	},
	checkCookie: function(){
		let favArry = Cookies.get("vison_favorite");
		if(!favArry){
			favorite.list = [];
		}else{
			favorite.list = JSON.parse(favArry);
		}
	},
	checkFavorite: function(value){
		let arry = [];
		if(favorite.list.length === 0){
			arry.push(value);
			favorite.list = arry;
			arry = JSON.stringify(arry);
		}else{
			favorite.list.push(value);
			favorite.list = uniq(favorite.list);
			arry = JSON.stringify(favorite.list);
		}
		//console.log("check:",arry)
		favorite.set(arry);
	},
	set: function(arry){
		Cookies.set('vison_favorite', arry, {
			//secure: true,
			expires: favorite.expire,
			domain: location.domainname
		});
		//console.log("favorite list:", arry);
	},
	postFavorite: function(id){
		let url = domain + apiURL.like;
		let param = {
			id: id
		}
		//console.log(url, param)
		load.postJson(url, param).done(function (json) {
			//console.log(json);
			let likeNum = json.Likes;
			$(`[data-post-id="${json.ArticleID}"]`).find("li.favorite p.num").html(likeNum);
		});
	},
	getMyFavorite: function(){
		let favorites = "";
		for(var i = 0; i < favorite.list.length; i++){
			//console.log( favorite.list[i])
			if(i === favorite.list.length-1){
				favorites += favorite.list[i];
			}else{
				favorites += favorite.list[i] + ",";
			}
		}
		return favorites;
	}
}
var star = {
	list: [],
	checkCookie: function(){
		let starArry = Cookies.get("vison_star");
		if(!starArry){
			star.list = [];
		}else{
			star.list = JSON.parse(starArry);
		}
	},
	addStarToShop: function(){
		for(var i = 0; i < star.list.length; i++){
			let f = parseInt( star.list[i] );
			for(var j = 0; j < $("a.btn_star").length; j++){
				let $this = $("a.btn_star").eq(j);
				if( parseInt( $this.attr("data-id") ) === f ){
					if( !$this.hasClass("active") ){
						$this.addClass("active");
					}
				}
			}
		}
	},
	removeStar: function(value){
		for(var i = 0; i < star.list.length; i++){
			let f = parseInt( star.list[i] );
			if(parseInt(value) === f){
				star.list.splice(i, 1);
				let arry = JSON.stringify(star.list);
				star.set(arry);
			}
		}
	},
	checkStar: function(value){
		star.checkCookie();
		console.log(star.list, value);
		let arry = [];
		if(star.list.length === 0){
			arry.push(value);
			star.list = arry;
			arry = JSON.stringify(arry);
			console.log("here", arry)
		}else{
			star.list.push(value);
			star.list = uniq(star.list);
			arry = JSON.stringify(star.list);
		}
		console.log("check:",arry)
		star.set(arry);
	},
	set: function(arry){
		Cookies.set('vison_star', arry, {
			//secure: true,
			expires: favorite.expire,
			domain: location.domainname
		});
		console.log("star list:", arry);
	}
}
//配列から重複を取り除く
function uniq(array) {
	return Array.from(new Set(array));
}