let indexLoadList = [0,0,0,0,0];
let loadTimer, newsTimer;
let categories = [];
let loadpost = {
	isReady: false,
	init: function () {
		if (isPage === "index") {
			loadpost.loadForIndex();
			loadpost.checkNews();
		}
	},
	loadForIndex: function(){
		loadpost.getTags();
		loadpost.getAreas();
		loadpost.getAreazones();
		loadpost.getCategory();
		loadpost.getOption();
		loadTimer = setInterval(function(){
			checkReady();
		}, 200);
		function checkReady(){
			if( indexLoadList.indexOf(0) > -1){
			}else{
				loadpost.isReady = true;
				clearInterval(loadTimer);
				osm.nav();
				home.nav();
			}
		}
	},
	getTags: function () {
		let url = domain + apiURL.tags;
		load.getJson(url, {}).done(function (json) {
			for (var i = 0; i < json.length; i++) {
				magazine.tags.push(json[i]);
				//トップの初回ロード時
				if(!loadpost.isReady){
					if(i === json.length - 1){
						indexLoadList[0] = 1;
					}
				}
			}
		});
	},
	getAreas: function () {
		let url = domain + apiURL.area;
		load.getJson(url, {}).done(function (json) {
			for (var i = 0; i < json.length; i++) {
				magazine.areas.push(json[i]);
				//トップの初回ロード時
				if(!loadpost.isReady){
					if(i === json.length - 1){
						indexLoadList[1] = 1;
					}
				}
			}
		});
	},
	getAreazones: function () {
		let url = domain + apiURL.areazone;
		load.getJson(url, {}).done(function (json) {
			for (var i = 0; i < json.length; i++) {
				magazine.areazones.push(json[i]);
				//トップの初回ロード時
				if(!loadpost.isReady){
					if(i === json.length - 1){
						indexLoadList[2] = 1;
					}
				}
			}
		});
	},
	getCategory: function () {
		let url = domain + apiURL.category;
		load.getJson(url, {}).done(function (json) {
			for (var i = 0; i < json.length; i++) {
				magazine.categories.push(json[i]);
				//トップの初回ロード時
				if(!loadpost.isReady){
					if(i === json.length - 1){
						indexLoadList[3] = 1;
					}
				}
			}
		});
	},
	getOption: function () {
		//固定のINFORMATION
		let url = domain + apiURL.option;
		load.getJson(url, {}).done(function(json) {
			osm.optionList = json;
			osm.addOptionSpots();
		});
	},
	getPost: function () {
		let url = domain + apiURL.post;
		let param = {
			categoryid 	: home.category,
			tagid 		: home.tag,
			cityid 		: home.cityid,
			areaid 		: home.areaid,
			page		: home.page,
			favorite	: false,
			latlng		: home.laglng
		};
		
		load.getJson(url, param).done(function (json) {
			//console.log("articles:", json)
			loadpost.makeList(json);
			loadpost.afterLoad();
		});
	},
	getPostByLatLng: function(){
		//記事、ピンをリセット
		//ここではフィルターをリセットする？
		osm.articles 	= [];
		home.hidePost();
		home.isFiltered = true;
		magazine.posts = [];
		console.log("map.getPostByLatLng: マップの中心が変わりました:", home.latlng);
		//
		let url = domain + apiURL.post;
		let param = {
			categoryid 	: home.category,
			tagid 		: home.tag,
			cityid 		: home.cityid,
			areaid 		: home.areaid,
			page 		: home.page,
			favorites 	: home.favorites,
			latlng 		: home.laglng
		};
		load.getJson(url, param).done(function(json){
			//console.log( json );
			loadpost.makeList(json);
			loadpost.afterLoad();
			
		});
	},
	getFilteredPost: function(){
		let url = domain + apiURL.post;
		let param = {
			categoryid 	: home.category,
			tagid 		: home.tag,
			cityid 		: home.cityid,
			areaid 		: home.areaid,
			page 		: home.page,
			favorites 	: home.favorites,
			latlng 		: home.laglng
		};
		//console.log("filtered:", param);
		if( $html.classList.contains("show_map") && $(".nav_list").is(":visible") ){
			$("#block_magazine .nav_list").animate({
				height: "toggle", opacity: "toggle"
			}, 400);
		}
		load.getJson(url, param).done(function (json) {
			//console.log( json );
			if(json.city.length > 0){
				magazine.filterArea = json.city[0];
				//console.log(magazine.filterArea)
			}
			loadpost.makeList(json);
			loadpost.afterLoad();
		});
	},
	loadMore: function () {
		let url = "/api/post.json";
		let param = {};
		load.getJson(url, param).done(function (json) {
			console.log(json);
			loadpost.makeList(json);
			loadpost.afterLoad();
		});
	},
	makeList: function (json) {
		magazine.maxCount 	= json.allPost;
		magazine.maxPage 	= json.maxPage;
		
		let len = 0;
		//console.log(json);
		//お気に入りのソートの場合はタイトル枠をトルツメ
		if(!json.post){
			len = 0;
		}else{
			len = json.post.length;
		}
		let start = 0;
		let end = len;
		end = end > json.each ? json.each : end;
		for (var i = start; i < end; i++) {
			//console.log(json.post[i])
			let d = json.post[i];
			let categoryLen = d.category.length;
			let tagLen = d.tag.length;
			let tagArry = [];
			let catArry = [];
			let total = categoryLen + tagLen;
			let catCount = 0;
			let tagCount = 0;
			
			for(var c = 0; c < categoryLen; c++){
				if(c < 3){
					catArry.push(d.category[c]);
					catCount++;
				}
			}
			for(var t = 0; t < tagLen; t++){
				if(t < 2){
					tagArry.push(d.tag[t]);
					tagCount++;
				}
			}
			let thisTotal = tagCount + catCount;
			
			if(thisTotal < 5){
				if(catCount < 3 && tagLen > 2){
					let totalLen = tagCount + catCount;
					for(var tt = 0; tt < (5 - totalLen); tt++){
						tagArry.push(d.tag[tt + tagCount]);
					}
				}else if( categoryLen > 3 && tagLen < 2 ){
					let totalLen = tagCount + catCount;
					for(var tt = 0; tt < (5 - totalLen); tt++){
						catArry.push(d.category[tt + catCount]);
					}
				}
			}
			
			//console.log(catCount, tagCount);
			d.category 	= catArry;
			d.tag 		= tagArry;
			
			//console.log(d);
			//console.log( JSON.stringify(json.post[i]) )
			magazine.posts.push(d);
		}
		magazine.allposts = magazine.posts;
		/*
		gMap.init(34.462634, 136.523483);
		*/
		//osm.addSpots(json);
	},
	afterLoad: function () {
		//console.log("afterLoad");
		osm.changeZoom();
		loadpost.nav();
		if( $html.classList.contains("update") ){
			$html.classList.remove("update");
		}
		home.canChangeArticle = true;
		//絞り込みがされている場合、option系のピンは小さくする
		if( home.isFiltered ){
			if( !$html.classList.contains("sort") ){
				$html.classList.add("sort");
			}
		}else{
			if( $html.classList.contains("sort") ){
				$html.classList.remove("sort");
			}
		}
		if(home.searchBy === "favorite"){
			if( !$("#block_filtered_header").hasClass("disable") ){
				$("#block_filtered_header").addClass("disable");
				$(".filter_favorite a").addClass("active");
			}
		}else{
			if( $("#block_filtered_header").hasClass("disable") ){
				$("#block_filtered_header").removeClass("disable");
				$(".filter_favorite a").removeClass("active");
			}
		}
		osm.showNewSpot();
		common.resize();
		
		//絞り込み時のタイトル表示
		if( home.searchBy === "tag" ){
			magazine.filteredName = "#" + $(`#list_search_tag li a[data-filter-id="${home.tag}"]`).attr("title");
			$("#block_filtered_header").addClass("tag");
			
		}else if( home.searchBy === "cityid" ){
			$(`#list_search_city li a[data-filter-id="${home.cityid}"]`).addClass("active");
			//絞り込み時に出すタイトル
			//magazine.filteredName = $(`#list_search_place li a[data-filter-id="${home.cityid}"]`).attr("title");
			
		}else if( home.searchBy === "area" ){
			$(`#list_search_area li a[data-filter-id="${home.areaid}"]`).addClass("active");
			//絞り込み時に出すタイトル
			magazine.filteredName = "VISON : " + $(`#list_search_area li a[data-filter-id="${home.areaid}"]`).attr("title");
			$("#block_filtered_header").addClass("visonarea");
		}
		
		setTimeout(function () {		
			favorite.addFavoriteToPost();
			common.resize();
			home.nav();
			if ($("body").hasClass("disable")) {
				$("body").removeClass("disable");
			}
			if( $("#block_magazine").hasClass("disable") ){
				$("#block_magazine").removeClass("disable");
			}
			home.canChangePost = true;
			common.scrl();
			for(var i = 0; i < 2; i++){
				$("#list_magazine li.p").eq(i).addClass("animate");
			}
		}, 600);
	},
	nav: function () {
		$("a.btn_more").off().on("click", function () {
			magazine.page++;
			if (magazine.page <= magazine.maxPage - 1) {
				loadpost.getPost();
				if (magazine.page === magazine.maxPage - 1) {
					$("#more").remove();
				}
			}
		});
		$("#block_magazine #nav_filter li a").off().on("click", function () {
			let idx = $("#nav_filter li a").index(this);
			//console.log(idx);
			loadpost.toggleSearchBlock(idx);
		});
	},
	closeSearchBlock: function(){
		$("#nav_filter li a").removeClass("active");
		let $block = $("#modal_search_list");
		$("#modal_search_list").removeClass();
		$(".search_list_inner").css({
			"display": ""
		});
	},
	toggleSearchBlock: function (idx) {
		$("#nav_filter li a").removeClass("active");
		$("#nav_filter li").eq(idx).find("a").addClass("active");
		let $block = $("#modal_search_list");
		let cls = "active" + idx;
		let hasCls = false;
		//アクティブなブロックを先にblockにしないと、アニメーションしないため、classをつける
		if (!$block.hasClass("open")) {
			if (!$block.hasClass(cls)) {
				activeBlock(cls);
			} else {
				hasCls = true;
			}
		} else {
			if (!$block.hasClass(cls)) {
				hasCls = false;
			} else {
				hasCls = true;
			}
		}
		//console.log("toggle search block", idx, cls);
		//
		setTimeout(function () {
			if ($block.hasClass("open")) {
				if (hasCls) {
					//閉じる
					$("#modal_search_list .search_list_inner").animate({
						height: "toggle",
						opacity: "toggle",
					},400);
					$("#nav_filter li a").removeClass("active");
					$block.removeClass("open");
					setTimeout(function () {
						$("#modal_search_list").removeClass();
					}, 400);
				} else {
					activeBlock(cls);
				}
			} else {
				$("#modal_search_list").css({
					top: "",
				});
				$("#modal_search_list .search_list_inner").animate({
					height: "toggle",
					opacity: "toggle",
				},400);
				$block.addClass("open");
			}
			osm.checkFloatModal();
		}, 100);
	
		function activeBlock(cls) {
			$block.removeClass(function (index, className) {
				return (className.match(/\bactive\S+/g) || []).join(" ");
			});
			$block.addClass(cls);
		}
	},
	checkNews: function(){
		let url = domain + "/api/news.php";
		let param = {};
		let max = 0;
		let data;
		load.getJson(url, param).done(function (json) {
			let HTML = "";
			data 	= json.post;
			max 	= json.allposts;
			let count = 0;
			console.log(json);
			if(!data){
				loadpost.closeNews();
			}else{
				if(data.length > 0){
					for(var i = 0; i < data.length; i++){
						let d = data[i];
						if(d.link != ""){
							HTML += `<li><a href="${d.link}">${d.title}</a></li>`
						}else{
							HTML += `<li>${d.title}</li>`
						}
					}
					$("#float_news .txt ul").html(HTML);
					$("#float_news").addClass("active");
					change();
					$("#float_news a#btn_close_float").off().on("click", function(){
						loadpost.closeNews();
					});
				}else{
					$("#float_news").remove();
				}
				if( max > 1 ){
					newsTimer = setInterval(function(){
						inc();
					}, 4000);
				}
			}
			
			function inc(){
				count++;
				if(count >= max){
					count = 0;
				}
				change();
			}
			function change(){
				$("#float_news").removeClass("emergency");
				//console.log(data[count])
				//console.log(data[count], count);
				if(data[count].digest === "emergency"){
					$("#float_news").addClass("emergency");
				}
				$("#float_news .txt ul li").removeClass("active");
				$("#float_news .txt ul li").eq(count).addClass("active");
			}
		});
		
	},
	closeNews: function(){
		$("#float_news").removeClass("active");
		$("a#btn_toggle_map_sp").removeClass("avoid");
		clearInterval(newsTimer);
		setTimeout(function(){
			$("#float_news").remove();
		}, 600);
	}
};
