var YT,ytPlayer,hasYoutube,ytTimer;
var ytReady = false;
function onYouTubeIframeAPIReady() {
	ytReady = true;
}
var ytMovie = {
	id: "",
	total: 0,
	state: 0,
	canPlay: false,
	isPlaying: false,
	init: function(){
		let playerID = $("#ytPlayer")[0];
		ytPlayer = new YT.Player(playerID, {
			videoId 	: ytMovie.id,
			playerVars 	: {
				autohide	: 1,
				controls	: 0,
				frameborder : 0,
				rel			: 0,
				start		: 0,
				showinfo 	: 0,
				disablekb	: 1,
				wmode		: "transparent",
				autoplay	: 0,
				mute 		: 1,
				playsinline	: 1,
				enablejsapi	: 1,
				iv_load_policy: 3,
				origin: location.protocol + '//' + location.hostname + "/"
			},
			events : {
				onReady: function(e){
					hasYoutube  	= true;
					ytPlayer 		= e.target;
					ytMovie.canPlay = true;
					ytPlayer.volume = 0;
					ytPlayer.playVideo();
					Cookies.set('vison_visited', "1", {
						//secure: true,
						expires: favorite.expire,
						domain: location.domainname
					});
					
					ytTimer = setInterval(function(){
						let time = ytPlayer.getCurrentTime();
						ytMovie.total = ytPlayer.getDuration();
						let $scene;
						
						//console.log(time, ytMovie.total);
						if(time > 2){
							if( !$("#splash").hasClass("show_movie") ){
								$("#splash").addClass("show_movie");
							}
							if(time > 4){
								if( !$(".scene_content").hasClass("scene1") ){
									$(".scene_content").addClass("scene1");
								}
							}
							if(time > 6.5){
								if( !$(".scene_content").hasClass("scene2") ){
									$(".scene_content").addClass("scene2");
									$("a.btn_skip").addClass("active");
								}
							}
							if(time > 9.4){
								if( !$(".scene_content").hasClass("scene3") ){
									$(".scene_content").addClass("scene3");
								}
							}
							if(time > 10){
								if( !$("#scene_txt").hasClass("active") ){
									$("#scene_txt").addClass("active");
								}
							}
							/*
							if(time > 18){
								$("a.btn_skip").addClass("active");
							}
							*/
						}
						if(time >= ytMovie.total - 1){
							ytMovie.close();
						}
					}, 100);
					$("a.btn_skip").off().on("click", function(){
						let cat 		= "ムービースキップ";
						let thisLabel 	= "skip";
						common.sendGa(cat, thisLabel);
						ytMovie.close();
					});
				},
				onStateChange: function(e){
					console.log(e.data);
					if(e.data === 1){
						ytMovie.isPlaying = true;
						ytMovie.state = 1;
					}
				},
				onError: function(e){
					var errorstatus = e.data;
				}
			}
		});
	},
	close: function(){
		$html.classList.remove("splash");
		clearInterval(ytTimer);
		setTimeout(function(){
			common.resize();
			common.scrl();
			$html.classList.remove("notransition");
			$html.classList.add("show_main");
		}, 1000);
	},
	delete: function(){
		$html.classList.add("notransition");
		setTimeout(function(){
			$html.classList.remove("splash");
			setTimeout(function(){
				$html.classList.remove("notransition");
				$html.classList.add("show_main");
			}, 100);
		},50);
	}
}