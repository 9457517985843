var fs = function(rx,ry,minw,minh,ww,wh){
	var winW = ww;
	var winH = wh;
	
	var posX = 0;
	var posY = 0;
	
	var ratioX = rx;
	var ratioY = ry;
	
	var videoW = 0;
	var videoH = 0;
	
	let minW = minw;
	let minH = minh;
	
	
	var $body = $("body");
	if(winW <= minW){
		winW = minW;
	}else{
		
	}
	
	if(winW <= winH){
		//縦長
		if(winH <= minH){
			//最小
			videoH = minH;
			videoW = minH / ratioY * ratioX;
			posX 	= (winW - videoW) / 2;
			posY 	= (winH - videoH) / 2;
		}else{
			//縦長
			videoH 	= winH;
			videoW 	= winH / ratioY * ratioX;
			posX 	= (winW - videoW) / 2;
			posY 	= (winH - videoH) / 2;
		}
	}else if(winW > winH){
		//横長
		if(winH <= minH){
			//横長
			videoH = winH;
			videoW = winH / ratioY * ratioX;
			posX 	= (winW - videoW) / 2;
			posY 	= (minH - videoH);
		}else{
			videoH = winH;
			videoW = videoH*ratioX / ratioY;
			posX 	= (winW - videoW) / 2;
			posY 	= (winH - videoH)/2;
		}
		if(videoW < winW){
			videoW = winW;
			videoH = winW / ratioX * ratioY;
			posX 	= (winW - videoW) / 2 * -1;
			posY 	= (winH - videoH)/2;
		}
	}
	return [Math.floor(videoW), Math.floor(videoH), Math.floor(posX), Math.floor(posY)];
}