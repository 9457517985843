var vCategories = {
	name : "vCategories",
	props: ['category'],
	template: 	`<li>
					<a href="javascript:void(0);" :title=category.name :data-category=category.id>
						<div class="col2">
							<div class="icon_text">
								<p>{{category.initial}}</p>
							</div>
							<div class="txt_r">
								<span class="nav_jp">{{category.name}}</span>
								<span class="nav_en">{{category.roman}}</span>
							</div>
						</div>
					</a>
				</li>`,
	methods: {
		
	}
}