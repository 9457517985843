
var vPost = {
	name : "vPost",
	props: ['post'],
	template: `<li class="p a" :data-post-id=post.id>
				<div class="list_post">
					<a :href=post.link :title="post.title | removeHTML">
						<div class="img"><figure :style="{ 'background-image': 'url( ' +  checkImage(post.thumbnail) + ' )' }"></figure></div>
						<div class="txt">
							<h3 v-html="post.title"></h3>
							<p class="lead" v-html="post.text"></p>
						</div>
					</a>
					<div class="article_info">
						<div class="flex">
							<dl>
								<dt>{{post.author}}</dt>
								<dd>{{post.author_belongs}}</dd>
							</dl>
							<p class="date">{{ post.date | checkDate }}</p>
						</div>
						<div class="cols">
							<ul class="tags">
								<li v-for="c in post.category"><a :href="c.id | getCategoryLink"><span>{{c.initial}}</span></a></li>
								<li v-for="tag in post.tag"><a :href="tag.id | getTagLink"><span>#{{tag.name}}</span></a></li>
								<li class="favorite"><div class="icon"></div><p class="num">{{post.like}}</p></li>
							</ul>
							<a href="javascript:void(0);" title="Like" class="btn_favorite" v-on:click="toggleFavorite(post.id)" :data-id=post.id><span></span></a>
						</div>
					</div>
				</div>
			</li>`
	,
	mounted(){
		//componentがマウントされたら一回づつ呼ばれる
		//console.log("component mounted")
	},
	filters: {
		removeHTML: function(value){
			return value.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'')
		},
		checkDate: function(value){
			let d = value.split("-").join("/");
			return d;
		},
		getCategoryLink: function(value){
			return "/?category=" + value;
		},
		getTagLink: function(value){
			return "/?tag=" + value;
		}
	},
	methods: {
		checkImage: function(value){
			//console.log(value)
			var format = new RegExp('([^\s]+(\\.(jpeg|jpg|png|gif|pdf))$)', 'i');
			let isImage = format.test(value);
			let img = value;
			if(!isImage){
				img = "/assets/images/article/noimage.png";
			}
			return img;
		},
		toggleFavorite: function(value){
			let $this = $('a.btn_favorite[data-id="' + value + '"]');
			if( $this.hasClass("active") ){
				$this.removeClass("active");
				favorite.removeFavorite(value);
			}else{
				favorite.postFavorite(value);
				$this.addClass("active");
				favorite.checkFavorite(value);
			}
		}
	}
}
